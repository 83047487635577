import { Table, TableProps } from "antd";
import React from "react";

interface DataType {
  key: string;
  date: string;
  name: string;
  type: string;
  operator: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "Дата создания",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Тип",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Оператор",
    key: "operator",
    dataIndex: "operator",
  },
];

const data: DataType[] = [
  {
    key: "1",
    name: "item1",
    date: "date 1",
    type: "type",
    operator: "operator",
  },
  {
    key: "2",
    name: "item1",
    date: "date 1",
    type: "type",
    operator: "operator",
  },
  {
    key: "3",
    name: "item1",
    date: "date 1",
    type: "type",
    operator: "operator",
  },
];

export default function TerminalDocs() {
  return <Table className="terminal__appeals-table" columns={columns} dataSource={data} pagination={false} />;
}
