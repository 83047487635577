import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TerminalMain from "../components/Terminal/TerminalMain/TerminalMain";
import TerminalSettings from "../components/Terminal/TerminalSettings/TerminalSettings";
import TerminalJurnal from "../components/Terminal/TerminalJorney/TerminalJurnal";
import { getTerminal, getTerminalConfig } from "../store/terminals/terminalsThink";
import { useAppDispatch, useAppSelector } from "../store";
import { setTerminal, setTerminalConfig } from "../store/terminals/terminalsSlice";
import TerminalAppeals from "../components/Terminal/TerminalAppeals/TerminalAppeals";

const tabs = ["Основное", "Настройки", "Журнал", "Обращения"];

export default function TerminalPage() {
  const terminalInfo = useAppSelector((state) => state.terminals.currentTerminal);
  const [page, setPage] = useState<string>("Основное");
  const role = useAppSelector((state) => state.auth.role);
  const dispatch = useAppDispatch();

  const params = useParams();
  let id = params.id;
  console.log(role);

  useEffect(() => {
    if (id) {
      dispatch(getTerminal(id));
      dispatch(getTerminalConfig(id));
    }
    return () => {
      dispatch(setTerminal(null));
      dispatch(setTerminalConfig(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {terminalInfo && (
        <div className="terminal">
          <div className="terminal__title title">{terminalInfo.name}</div>

          <Tabs
            defaultActiveKey="1"
            type="card"
            size="large"
            onChange={setPage}
            items={tabs
              .filter((item) => {
                if (role !== "Admin" && item === "Настройки") {
                  return false;
                }
                return true;
              })
              .map((string) => {
                return {
                  label: `${string}`,
                  key: `${string}`,
                };
              })}
          />

          {id && (
            <div className="terminal__wrap">
              {page === "Основное" && <TerminalMain id={id} ip={terminalInfo.currentIP} />}
              {page === "Настройки" && role === "Admin" && <TerminalSettings />}
              {page === "Журнал" && <TerminalJurnal id={id} />}
              {page === "Обращения" && <TerminalAppeals id={id} />}
            </div>
          )}
        </div>
      )}
    </>
  );
}
