import { Button } from "antd";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VncScreen } from "react-vnc";

export default function VncSession() {
  let { ip } = useParams();
  const ref = useRef();
  const navigate = useNavigate();

  return (
    <section className="vnc">
      <div className="container">
        <div className="vnc__title title">Управление ws://{ip}</div>
        <Button className="btn-back" onClick={() => navigate(-1)}>
          Назад
        </Button>
        <div className="vnc__inner">
          <VncScreen
            url={`wss://vnc.photondev.ru`}
            scaleViewport
            background="#000000"
            style={{
              width: "75vw",
              height: "75vh",
            }}
            //@ts-ignore
            ref={ref}
          />
        </div>
      </div>
    </section>
  );
}
