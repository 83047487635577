import { Button } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WEBRTC_URL } from "../configs/url";

export default function WebRTCSession() {
  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const listener = (event: any) => {
      if (event.data && event.data.type) {
        if (event.data.type === "close-iframe") {
          const isSuccess = event.data.success;
          if (!isSuccess) {
            navigate(-1);
          }
        }
      }
    };

    window.addEventListener("message", listener, false);
    return window.addEventListener("message", listener, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="webrtc">
      <div className="container webrtc__container">
        <div>
          <div className="vnc__title title">WebRTC</div>
          <Button className="btn-back" onClick={() => navigate(-1)}>
            Назад
          </Button>
        </div>
        <div className="webrtc__inner">
          <iframe
            title="webrtc"
            src={`${WEBRTC_URL}/${id}`}
            style={{ width: "100%", height: "100%" }}
            allow="camera; microphone; fullscreen"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  );
}
