import axios, { AxiosRequestHeaders } from "axios";
import Cookies from "js-cookie";
import { API_URL } from "../configs/url";

const instance = axios.create({
  headers: {
    accept: "application/json",
  },
  withCredentials: true,
  baseURL: API_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("accessToken");
    if (token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      } as unknown as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const instanceFile = axios.create({
  headers: {
    "Content-Type": "application/pdf",
    accept: "application/pdf",
  },
  withCredentials: true,
  baseURL: API_URL,
});

instanceFile.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("accessToken");
    if (token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      } as unknown as AxiosRequestHeaders;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export const axiosPrivate = instance;
export const axiosPrivateFile = instanceFile;
