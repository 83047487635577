export const MAIN_PATH = "/";
export const TERMINALS_PATH = "/terminals";
export const ERROR_PATH = "/404";
export const typeAppMobile = "mob";
export const typeAppDesktop = "desk";
export const VNC_PATH = "/vnc";
export const ADMIN_PATH = "/administration";
export const OPERATOR_PATH = "/administration/operator";
export const REPORTS_PATH = "/reports";
export const SETTINGS_PATH = "/settings";
export const WEBRTC_PATH = "/webrtc-session";
export const RTSP_PATH = "/rtsp-session";
