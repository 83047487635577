import { addAppeals, editUserType, getAbonents, getAppeals, newUserType } from "../utils/types/commonType";
import { getUsersType } from "../utils/types/storeType";
import { axiosPrivate, axiosPrivateFile } from "./axios";

export const authAPI = {
  login(email: string, password: string) {
    return axiosPrivate.post("auth/login", {
      email,
      password,
    });
  },

  refreshToken({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) {
    return axiosPrivate.post("auth/refresh_token", {
      accessToken,
      refreshToken,
    });
  },
};

export const terminalAPI = {
  getList({ UserId }: { UserId?: string }) {
    return axiosPrivate.get(`terminals/get_terminals${UserId ? `?UserId=${UserId}` : ""}`);
  },

  getTerminalInfo({ TerminalId }: { TerminalId: string }) {
    return axiosPrivate.get(`terminals/get_terminal_info?TerminalId=${TerminalId}`);
  },

  getUserTerminals() {
    return axiosPrivate.get(`terminals/get_user_terminals`);
  },

  setUserTerminals({ userId, terminalsId }: { userId: string; terminalsId: Array<string> }) {
    return axiosPrivate.patch(`terminals/set_user_terminals`, {
      userId,
      terminalsId,
    });
  },

  getTerminalConfig({ TerminalId }: { TerminalId: string }) {
    return axiosPrivate.get(`terminals/get_config?TerminalId=${TerminalId}`);
  },

  getStatusList() {
    return axiosPrivate.get(`terminals/status_list`);
  },

  deleteTerminal({ Id }: { Id: string }) {
    return axiosPrivate.delete(`terminals/delete_terminal?Id=${Id}`);
  },

  addNewterminal({
    name,
    currentIP,
    userId,
    astipConfigId,
  }: {
    name: string;
    currentIP: string;
    userId?: string;
    astipConfigId?: string;
  }) {
    return axiosPrivate.post("terminals/add_terminal", {
      name,
      currentIP,
      ...(userId && { userId }),
      ...(astipConfigId && { astipConfigId }),
    });
  },

  addNewStatus({ id, status }: { id: string; status: number }) {
    return axiosPrivate.patch(`terminals/set_status_terminal`, {
      id,
      status,
    });
  },
  editTerminal({
    id,
    name,
    currentIP,
    astipConfigId,
  }: {
    id: string;
    name: string;
    currentIP: string;
    astipConfigId?: string;
  }) {
    return axiosPrivate.patch(`terminals/edit_terminal`, {
      id,
      name,
      currentIP,
      ...(astipConfigId && { astipConfigId }),
    });
  },
  printDoc({ terminalId, file }: { terminalId: string; file: string }) {
    const blob = new Blob([file], {
      type: "application/pdf",
    });
    const myData = new FormData();
    myData.append("file", file);
    myData.append("terminalId", terminalId);
    return axiosPrivateFile.post(`terminals/print_doc`, myData);
  },
};

export const usersAPI = {
  getUsers({ UserName, Email, IsActive, Role, IsBlocked }: getUsersType) {
    let stroke = "";
    const object = {
      ...(UserName && { UserName }),
      ...(Email && { Email }),
      ...(IsActive && { IsActive }),
      ...(Role && { Role }),
      ...(IsBlocked && { IsBlocked }),
    };
    for (let key in object) {
      //@ts-ignore
      console.log(object[key]);
      //@ts-ignore
      stroke += `${key}=${object[key]}&`;
    }

    return axiosPrivate.get(`users/get_users${stroke ? `?${stroke}` : ""}`);
  },
  getUser({ UserId }: { UserId: string }) {
    return axiosPrivate.get(`users/get_user_info?UserId=${UserId}`);
  },
  addUser({ userName, email, password }: newUserType) {
    return axiosPrivate.post(`users/add`, {
      userName,
      email,
      password,
    });
  },
  switchStatus() {
    return axiosPrivate.patch(`users/switch_status`);
  },
  banUser({ UserId }: { UserId: string }) {
    return axiosPrivate.patch(`users/ban?UserId=${UserId}`);
  },
  unBanUser({ UserId }: { UserId: string }) {
    return axiosPrivate.patch(`users/unban?UserId=${UserId}`);
  },
  updateUser({ userName, email, id }: editUserType) {
    return axiosPrivate.post(`users/edit`, {
      userName,
      email,
      id,
    });
  },
  newPassUser({ UserId, password }: { UserId: string; password: string }) {
    return axiosPrivate.patch(`users/set_new_password`, {
      password,
      userId: UserId,
    });
  },
  getUsersLocations() {
    return axiosPrivate.get(`users/get_users_locations`);
  },
  setUsersLocations({ userId, locationId }: { userId: string; locationId: number }) {
    return axiosPrivate.patch(`users/set_user_location`, { userId, locationId });
  },
};

export const appealsAPI = {
  getList({ Type, Status, CreatedStart, AbonentId, AstipId, UserId, CreatedEnd }: getAppeals) {
    let stroke = "";
    const object = {
      ...(Type && { Type }),
      ...(Status && { Status }),
      ...(CreatedStart && { CreatedStart }),
      ...(CreatedEnd && { CreatedEnd }),
      ...(AbonentId && { AbonentId }),
      ...(AstipId && { AstipId }),
      ...(UserId && { UserId }),
    };
    for (let key in object) {
      //@ts-ignore
      stroke += `${key}=${object[key]}&`;
    }

    return axiosPrivate.get(`appeals/list${stroke ? `?${stroke}` : ""}`);
  },
  getStatutList() {
    return axiosPrivate.get(`appeals/status_list`);
  },
  getTypesList() {
    return axiosPrivate.get(`appeals/types_list`);
  },
  addAppeals({ type, status, comment, abonentId, terminalId }: addAppeals) {
    return axiosPrivate.post(`appeals/add`, {
      type,
      status,
      comment,
      abonentId,
      terminalId,
    });
  },
  getAppeals(AppealId: string) {
    return axiosPrivate.get(`appeals/get_info?AppealId=${AppealId}`);
  },
  addDocument({ appealId, documentId }: { appealId: string; documentId: string }) {
    return axiosPrivate.post(`appeals/add_document`, {
      appealId,
      documentId,
    });
  },
  setComment({ appealId, comment }: { appealId: string; comment: string }) {
    return axiosPrivate.patch(`appeals/set_comment`, {
      appealId,
      comment,
    });
  },
  setStatus({ appealId, appealStatus }: { appealId: string; appealStatus: number }) {
    return axiosPrivate.patch(`appeals/set_status`, {
      appealId,
      appealStatus,
    });
  },
};

export const abonentAPI = {
  addAbonent({ personalAcc, email, phoneNumber }: { personalAcc: string; email: string; phoneNumber: string }) {
    return axiosPrivate.post(`abonents/add`, {
      personalAcc,
      email,
      phoneNumber,
    });
  },
  getAbonent({ Filter }: { Filter?: string }) {
    let stroke = "";
    const object = {
      ...(Filter && { Filter }),
    };
    for (let key in object) {
      //@ts-ignore
      console.log(object[key]);
      //@ts-ignore
      stroke += `${key}=${object[key]}&`;
    }
    return axiosPrivate.get(`abonents/get_list${stroke ? `?${stroke}` : ""}`);
  },
};
