import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
var jsmpeg = require("jsmpeg");

export default function RtspSession() {
  let { ip } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const client = new WebSocket(`ws://${ip}:9999`);
    let canvas = document.getElementById("video-canvas");
    const player = new jsmpeg(client, {
      canvas: canvas,
      audio: false,
      videoBufferSize: 1024 * 1024,
    });

    return () => {
      client.close();
    };
  }, []);

  return (
    <section className="vnc rtsp">
      <div className="container rtsp-container">
        <div className="vnc__title title">Управление rtsp://{ip}</div>
        <Button className="btn-back" onClick={() => navigate(-1)}>
          Назад
        </Button>
        <div className="vnc__inner rtsp__inner">
          <canvas id="video-canvas"></canvas>
        </div>
      </div>
    </section>
  );
}
